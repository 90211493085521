<template>
    <div>
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="md-flex md-justify-between">
                <router-link to="/" class="footer__logo">
                  <img class="header_logo" src="assets/img/header_logo.svg" width="350" height="61" loading="lazy" alt="SES MEDIAのロゴ画像">
                </router-link>
                <div class="grid">
                  <div>
                    <p class="footer__navi-heading">サービス</p>
                    <ul class="footer__navi">
                      <li>
                        <p>SES業界の知識を身につけるなら</p>
                        <router-link class="animated-underline" to="/article">・SESノウハウ</router-link>
                      </li>
                      <li>
                        <p>協業先・ビジネスパートナーを探すなら</p>
                        <router-link class="animated-underline" to="/companylist">・SES企業・エージェント一覧</router-link>
                      </li>
                      <!--<li>
                        <p>SES業界での転職先探しなら</p>
                        <a class="animated-underline"  href="#">・SESスカウト</a>
                      </li>-->
                    </ul>
                  </div>
                  <div>
                    <p class="footer__navi-heading">規約・お問い合わせ・その他</p>
                    <ul class="footer__navi">
                      <li><router-link class="animated-underline" to="/terms">・利用規約</router-link></li>
                      <li><router-link class="animated-underline" to="/privacy">・プライバシーポリシー</router-link></li>
                      <li><a class="animated-underline" target="_blank" href="https://yurulica.com">・運営会社情報</a></li>
                      <li><a class="animated-underline" target="_blank" href="https://yurulica.com/contact">・サービスに関するお問い合わせ</a></li>
                      <li><a class="animated-underline" target="_blank" href="https://yurulica.com/contact">・提携・広告掲載のお問い合わせ</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr />
              <div class="md-flex md-justify-between">
                <p class="copyright">© 2023 <a target="_blank" href="https://yurulica.com/"> 株式会社ユルリカ</a> All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        created: function () {
        },
        mounted: function() {
        },
        computed: {
        },
        watch: {
        },
        methods: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
