const MixIn = {
    mounted: function () {
        // eslint-disable-next-line no-undef
        $("#navigation").navigation();
        this.toHash();
    },
    watch: {
        '$route': function (n) {
            if (n.hash.match(/^#/)) {
                setTimeout(() => {
                    this.scrollElementById(n.hash.replace(/^#/, ''));
                }, 0);
            }
        }
    },
    methods: {
        toHash() {
            if (this.$route.hash.match(/^#/)) {
                this.scrollElementById(this.$route.hash.replace(/^#/, ''));
            }
        },
        scrollElementById(id) {
            const element = document.getElementById(id);
            if (!element) {
                return;
            }

            const yOffset = -100;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
}

export default MixIn;