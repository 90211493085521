<template>
    <div>
        <div id="header" class="header header-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <nav id="navigation" class="navigation">
                            <!-- if Home -->
                            <div v-if="$route.name==='Home'" class="nav-header">
                                <h1>
                                <router-link class="nav-brand" to="/">
                                  <img class="header_logo" src="assets/img/header_logo.svg" width="275" height="47" alt="SES MEDIA｜IT・SES業界で働く方向け情報サイト">
                                </router-link>
                                </h1>
                              <div id="nav-toggle" class="nav-toggle"></div>
                            </div>
                            <!-- else Home -->
                            <div v-else class="nav-header">
                                <router-link class="nav-brand" to="/">
                                  <img class="header_logo" src="assets/img/header_logo.svg" width="275" height="47" alt="SES MEDIA｜IT・SES業界で働く方向け情報サイト">
                                </router-link>
                              <div id="nav-toggle" class="nav-toggle"></div>
                            </div>
      
                          <div class="nav-menus-wrapper" style="transition-property: none;">
                              <ul id="nav-menu" class="nav-menu nav-menu-side nav-menu-social align-to-right">
                                <li class="header-list-menu">
                                  <p class="header-accent">国内最大1,400社超掲載！</p>
                                  <div class="header-accent-bar"/>
                                  <p style="text-align: justify;"><router-link class="header-list header-nav-side" to="/companylist">SES企業・エージェント一覧</router-link></p>
                                </li>
                                  <template v-if="isLogin">
                                      <li class="add-listing simple-icon-btn" style="margin-top: 0;">
                                          <img class="menu-icon-mypage" src="assets/img/mypage-icon.svg" width="21" height="20" alt="マイページアイコン">
                                          <router-link to="/mypage">
                                              マイページ
                                          </router-link>
                                      </li>
                                  </template>
                                  <template v-else>
                                    <div class="header-menu-liframe">
                                      <p class="header-accent header-menu-li-accent">\ 無料で求人掲載するなら /</p>
                                      <li class="add-listing orange-btn">
                                          <img class="menu-icon-left" src="assets/img/user-icon.svg" width="25" height="25" alt="アカウントアイコン">
                                          <router-link to="/register">
                                              アカウント作成
                                          </router-link>
                                      </li>
                                    </div>
                                    <div class="header-menu-liframe">
                                      <p class="header-accent header-menu-li-accent">\ アカウント開設済みの方 /</p>
                                      <li class="add-listing simple-icon-btn">
                                        <router-link :to=this.loginPath class="sp-login-btn">
                                          ログイン
                                        </router-link>
                                        <img class="menu-icon-right" src="assets/img/enter-icon.svg" width="20" height="40" alt="ログインアイコン">
                                      </li>
                                    </div>
                                  </template>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isLogin} from "../../common/api";
    import { encodeURI } from 'js-base64';

    export default {
      name: 'Header',
        props: {
            isRedirect: {
                type: Boolean,
                default: true,
            }
        },
        data: function() {
            return {
                // loginPath: ""
            }
        },
        computed: {
            isLogin: {
                get() {
                    return isLogin();
                }
            },
            loginPath: {
                get() {
                    if (this.isRedirect) {
                        return `/login?redirectPath=${encodeURI(location.pathname)}`;
                    } else {
                        return `/login`;
                    }
                }
            }
        }
    }
</script>

<style scoped>
</style>
